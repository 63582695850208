/** @jsxImportSource @emotion/react */
import { keyframes } from "@emotion/react";
import React from "react";
import { Avatar, Heading, VStack } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";
import Hallab from "./Hallab.jpg";

const greeting = "Hello, I am Habeeb!";
const bio1 = "I'm a front-end developer who likes to craft solid and scalable front-end products";
const bio2 = " with great user experiences. Specialised in React, NextJs, and more";

// Keyframes for the background animation
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const LandingSection = () => (
  <FullScreenSection
    justifyContent="center"
    alignItems="center"
    isDarkBackground
    css={{
      background: "linear-gradient(90deg, #608066, #A2B9A5, #8FBC8F)",
      backgroundSize: "200% 200%",
      animation: `${gradientAnimation} 10s ease infinite`,
    }}
    color="#1D1E18"
    id="aboutme-section"
  >
    <VStack spacing={10}>
      <Avatar
        src={Hallab}
        size="2xl"
        boxSize="180px"
        name="Hallab"
      />

      <VStack spacing={4}>
        <Heading as="h1" size="2xl" noOfLines={1}>
          {greeting}
        </Heading>
        <Heading as="h3" size="md" noOfLines={1}>
          {bio1}
        </Heading>
        <Heading textAlign="center" as="h3" paddingBottom={5} size="md" noOfLines={3}>
          {bio2}
        </Heading>
      </VStack>
    </VStack>
  </FullScreenSection>
);

export default LandingSection;
