import React, { useEffect } from "react";
import { useFormik } from "formik";
import {
  background,
  Box,
  Button,
  color,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import FullScreenSection from "./FullScreenSection";
import useSubmit from "../hooks/useSubmit";
import { useAlertContext } from "../context/alertContext";
import { width } from "@fortawesome/free-brands-svg-icons/fa42Group";

const LandingSection = () => {
  const { isLoading, response, submit } = useSubmit();
  const { onOpen } = useAlertContext();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      email: '',
      type: '',
      comment: '',
    },
    onSubmit: (values) => {
      // First, submit the form data
      submit('https://hallab.com/contactme', values);
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Required'),
      email: Yup.string().email("Invalid email address").required('Required'),
      comment: Yup.string().min(25, "Must be at least 25 characters").required('Required')
    }),
  });

  useEffect(() => {
    if (response) {
      onOpen(response.type, response.message);
      if (response.type === 'success') {
        // Only send data to Telegram if form submission was successful
        sendToTelegram(formik.values);
        formik.resetForm();
      }
    }
  }, [response]);

  // Function to send data to Telegram
  const sendToTelegram = (values) => {
    const botToken = "7608059052:AAF9PBEKuFUlfz53sicJg2pD0UVoGSzG4V4"; // Replace with your Telegram Bot Token
    const chatId = "7048781309"; // Replace with your chat ID
    const message = `
      New message from ${values.firstName} (${values.email}):

      Type of enquiry: ${values.type}
      
      Message: ${values.comment}
    `;

    const url = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(message)}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // if (data.ok) {
        //   // Optionally, you can use your alert system here to notify successful Telegram message sending
        //   onOpen("success", "Message sent to Telegram successfully!");
        // } else {
        //   onOpen("error", "Failed to send the message to Telegram.");
        // }
      })
      // .catch((error) => {
      //   console.error("Error:", error);
      //   onOpen("error", "An error occurred while sending the message to Telegram.");
      // });
  };

  return (
    <FullScreenSection isDarkBackground style={{backgroundColor:"#B9F5D8", width: "100%"}} py={1} spacing={8}>
      <VStack w="1024px" p={32} alignItems="flex-start">
        <Heading as="h1" id="contactme-section">
          Contact me
        </Heading>
        <Box p={6} rounded="md" w="100%">
          <form onSubmit={formik.handleSubmit}>
            <VStack spacing={4}>
              <FormControl isInvalid={!!formik.errors.firstName && formik.touched.firstName}>
                <FormLabel htmlFor="firstName">Name</FormLabel>
                <Input borderColor="#1D1E18" _hover={{borderColor:"#6B8F71"}} id="firstName" name="firstName" {...formik.getFieldProps('firstName')} />
                <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!formik.errors.email && formik.touched.email}>
                <FormLabel htmlFor="email">Email Address</FormLabel>
                <Input borderColor="#1D1E18" _hover={{borderColor:"#6B8F71"}} id="email" name="email" type="email" {...formik.getFieldProps('email')} />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="type">Type of enquiry</FormLabel>
                <Select borderColor="#1D1E18" _hover={{borderColor:"#6B8F71"}} id="type" name="type" {...formik.getFieldProps('type')}>
                  <option value="hireMe">Freelance project proposal</option>
                  <option value="openSource">Open source consultancy session</option>
                  <option value="other">Other</option>
                </Select>
              </FormControl>
              <FormControl isInvalid={!!formik.errors.comment && formik.touched.comment}>
                <FormLabel htmlFor="comment">Your message</FormLabel>
                <Textarea borderColor="#1D1E18" _hover={{borderColor:"#6B8F71"}} id="comment" name="comment" height={250} {...formik.getFieldProps('comment')} />
                <FormErrorMessage>{formik.errors.comment}</FormErrorMessage>
              </FormControl>
              <Button type="submit" background="linear-gradient(90deg, #1D1E18, #00BFA6)" style={{color:"#B9F5D8"}} width="full" _hover={{background: "linear-gradient(90deg, #00BFA6, #1D1E18)"}}>
                Submit
              </Button>
            </VStack>
          </form>
        </Box>
      </VStack>
    </FullScreenSection>
  );
};

export default LandingSection;
