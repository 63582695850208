import { Heading, HStack, Image, Text, VStack, Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";

const Card = ({ title, description, imageSrc }) => {
  const [showMore, setShowMore] = useState(false); // State to track if the description is expanded

  const toggleDescription = () => {
    setShowMore(!showMore); // Toggle the state on click
  };

  return (
    <VStack
      color="#1D1E18"
      backgroundColor="#D9FFF5"
      cursor="pointer"
      borderRadius={10}
      boxShadow="lg"
    >
      <Image src={imageSrc} borderRadius={10} />
      <VStack spacing={4} p={4} alignItems="flex-start">
        <HStack justifyContent="space-between" alignItems="center">
          <Heading as="h3" size="md">
            {title}
          </Heading>
        </HStack>

        <Box
          maxHeight={showMore ? "none" : "none"} // Limit height when not expanded
          overflow="hidden" // Hide overflowed content
          transition="max-height 0.3s ease-in-out"
        >
          <Text color="#1D1E18" fontSize="lg">
            {description}
          </Text>
        </Box>

        <HStack onClick={toggleDescription} style={{fontWeight:"bold"}}>
          <p
            
            
          >
            {showMore ? "See Less" : "See More"}
          </p>
          <FontAwesomeIcon
            icon={faArrowRight}
            size="1x"
            style={{
              transform: showMore ? "rotate(90deg)" : "rotate(0deg)", // Rotate the arrow icon
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </HStack>
      </VStack>
    </VStack>
  );
};

export default Card;
