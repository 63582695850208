import React from "react";
import FullScreenSection from "./FullScreenSection";
import { Box, Heading } from "@chakra-ui/react";
import Card from "./Card";

const projects = [
  {
    title: "A modern Food Ordering App",
    description:
      "Foodhub is a sleek and responsive food ordering application built using Next.js, designed to provide a seamless user experience for food enthusiasts. The app leverages Next.js routing for smooth navigation and an SQL database for efficient and secure data management.",
    getImageSrc: () => require("../images/photo5.png"),
  },
  {
    title: "A Modern News Aggregator App",
    description:
      "NextJS News is a minimalist, responsive news aggregator built with Next.js, designed to deliver real-time news updates from various categories. It provides users with a clean interface for seamless navigation and an enjoyable browsing experience",
    getImageSrc: () => require("../images/photo6.png"),
  },

  {
    title: "React To-do App",
    description:
      "A simple yet powerful application designed to help users manage their tasks and to-do lists efficiently. The main goal is to allow users to track, organize, and prioritize tasks, ensuring that nothing important is forgotten or overlooked",
    getImageSrc: () => require("../images/photo1a.png"),
  },
  {
    title: "E-commerce Product Page",
    description:
      "An e-commerce product page is a crucial part of an online shopping platform, designed to showcase individual products to potential customers. This page provides detailed information about the product, helping customers make informed purchasing decisions",
    getImageSrc: () => require("../images/photo2.png"),
  },

  {
    title: "CryptoMingle Whitepaper App",
    description:
      "CryptoMingle is an innovative web application built with React that provides users with a centralized platform to discover, read, and compare cryptocurrency whitepapers. Designed to enhance accessibility and understanding of blockchain projects, CryptoMingle offers a user-friendly interface with features tailored for enthusiasts, researchers, and investors.",
    getImageSrc: () => require("../images/photo7.png"),
  },
  {
    title: "A React poster App App",
    description:
      "The React Poster App is a versatile web application designed for showcasing and exploring posters with an emphasis on a seamless user experience. Built with React, it incorporates intuitive routing and a sleek dark mode toggle, providing users with a modern and visually appealing interface.",
    getImageSrc: () => require("../images/photo8.png"),
  },

  {
    title: "Multi-Step Form",
    description:
      "A user interface design pattern used in web and mobile applications to break down a long or complex form into smaller, more manageable sections or steps. Instead of presenting all form fields on a single page, the multi-step form guides the user through a series of steps, each containing a subset of the overall form fields",
    getImageSrc: () => require("../images/photo3.png"),
  },
  {
    title: "Interactive Card Details Form",
    description:
      "A user interface element that allows users to securely enter and manage their payment card information in a visually engaging and user-friendly way. This type of form is commonly used in e-commerce websites, online services, and mobile applications where users need to provide credit or debit card details for transactions.",
    getImageSrc: () => require("../images/photo4a.png"),
  },
  
];

const ProjectsSection = () => {
  return (
    <FullScreenSection
      background="linear-gradient(90deg, #E8F5E9, #C8E6C9)"
      isDarkBackground
      p={8}
      alignItems="flex-start"
      spacing={8}
     style={{width: "100vw"}}
    >
      <Heading as="h1" id="projects-section"style={{ color: '#333333' }}>
        Featured Projects
      </Heading>
      <Box
        display="grid"
        gridTemplateColumns="repeat(2,minmax(0,1fr))"
        gridGap={8}
      >
        {projects.map((project) => (
          <Card
            key={project.title}
            title={project.title}
            description={project.description}
            imageSrc={project.getImageSrc()}
          />
        ))}
      </Box>
    </FullScreenSection>
  );
};

export default ProjectsSection;
